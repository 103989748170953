/* Header */
.header {
  height: 85px;
  font-family: "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #214966;
}

/* Heading */
.heading {
  height: 182px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #214966;
}

/* Button */
.button {
  width: 230px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border: 4px solid #63cd81;
  border-radius: 25px;
  font-family: "Roboto", sans-serif;
  font-size: 27px;
  color: #214966;
  transition: transform 0.2s ease-in-out;
  animation: bounce 0.5s;
}

.button:hover {
  transform: translateY(-8px);
  border-bottom-width: 15px;
}

/* Test */
.test {
  margin-left: 60px;
  margin-top: 100px;
}

.image1 {
  margin-top: -40px;
  width: 504.04px;
  height: 480px;
  float: right;
  margin-right: 60px;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  /* Header */
  .header {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 50px;
  }

  /* Heading */
  .heading {
    font-size: 24px;
    line-height: 32px;
  }

  /* Button */
  .button {
    width: 200px;
    height: 50px;
    font-size: 20px;
  }

  /* Test */
  .test {
    margin: 20px;
  }

  .image1 {
    margin-top: 20px;
    width: 100%;
    height: auto;
    float: none;
    margin-right: 0;
  }
}
/* Additional Media Query for Desktop Screens */
@media only screen and (min-width: 769px) {
  /* Header */
  .header {
    font-size: 42px;
    line-height: 48px;
  }

  /* Heading */
  .heading {
    font-size: 32px;
    line-height: 48px;
  }

  /* Button */
  .button {
    width: 230px;
    height: 60px;
    font-size: 27px;
    margin-top: 50px;
  }

  /* Test */
  .test {
    margin-left: 60px;
    margin-top: 100px;
  }

  .image1 {
    margin-top: -40px;
    width: 504.04px;
    height: 480px;
    float: right;
    margin-right: 60px;
  }
}

/* Adjustments to prevent text overlap */
@media only screen and (max-width: 1200px) {
  .test {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 992px) {
  .test {
    margin-top: 30px;
  }
}


/* Header */
.header1 {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  text-align: center;
  color: #214966;
}

/* Heading */
.heading1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #214966;
  margin-top: 30px;
  padding-left: 200px;
padding-right: 200px; /* Adjust padding for smaller screens */
}

/* Test container */
.test1 {
  text-align: center;
  margin-top: 50px;
}

/* Button container */
.butt {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

/* Button */
.button1 {
  width: 100%;
  max-width: 230px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border: 4px solid #63cd81;
  border-radius: 25px;
  font-family: "Roboto";
  font-size: 27px;
  color: #214966;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 80px;
}

/* Button hover */
.button1:hover {
  transform: translateY(-8px);
  border-bottom-width: 15px;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  /* Header */
  .header1 {
    font-size: 28px;
    line-height: 32px;
  }

  /* Heading */
  .heading1 {
    font-size: 24px;
    line-height: 32px;
    padding: 0 10px; /* Adjust padding for smaller screens */
  }

  /* Button */
  .button1 {
    font-size: 20px;
  }

  /* Button container */
  .butt {
    margin-top: 40px;
  }
 
}
.video {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the container horizontally */
  /* padding-bottom: 56.25%; Set the aspect ratio (16:9 for this example) */
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .video {
    padding-bottom: 20%; /* Adjust the aspect ratio for smaller screens */
  }
}

.image-container:before {
  transform: skewY(-10deg) translateX(-50%);
}

.image-container:after {
  transform: skewY(10deg) translateX(50%) scaleX(-1);
}

.image-container {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* hide scrollbar for Firefox */
  -ms-overflow-style: none; /* hide scrollbar for IE 10+ */
  margin-bottom: 100px;
  margin-top: 50px;
}

.image-container img {
  flex: 0 0 auto;
  width: 100%; /* set the desired width of the images */
  height: auto;
  margin-right: 10px; /* add some space between the images */
}
.image-container::-webkit-scrollbar {
  display: none; /* hide scrollbar for Chrome, Safari, and Opera */
}
@media (min-width: 768px) {
  .image-container img {
    width: 25%; /* make the images larger */
    margin-right: 20px; /* reduce the margins between them */
  }
}

@media (min-width: 992px) {
  .image-container img {
    width: 20%; /* make the images even larger */
    margin-right: 30px; /* further reduce the margins between them */
  }
}




.workshop {
  width: 405px;
  height: 400px;
  float: left;
  margin-top: 60px;
  margin-left: 70px;
  margin-right: 200px;
}

.header2 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  text-transform: uppercase;
  color: #214966;
}

.heading2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #214966;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .workshop {
    width: 100%;
    max-width: 405px;
    margin-left: auto;
    margin-right: auto;
  }

  .header2 {
    font-size: 28px;
    line-height: 32px;
  }

  .heading2 {
    font-size: 24px;
    line-height: 36px;
  }
}


.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 24px;
  gap: 40px;
  margin-top: -70px;
  border-radius: 0px;
}


.header3 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #214966;
}

.para {
  margin-top: -30px;
  font-size: 20px;
}

.button3 {
  width: 100%;
  max-width: 230px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border: 4px solid #63cd81;
  border-radius: 25px;
  font-family: "Roboto";
  font-size: 27px;
  color: #214966;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 80px;
}

/* Button hover */
.button3:hover {
  transform: translateY(-8px);
  border-bottom-width: 15px;
}

.whatsapp {
  width: 64px;
  margin-right: 10px;
  margin-left: -15px;
}

.hyper {
  text-decoration: none;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .testimonial {
    padding: 32px 16px;
  }

  .header3 {
    font-size: 28px;
    line-height: 36px;
  }

  .para {
    font-size: 18px;
  }

  .button3 {
    max-width: 200px;
    height: 50px;
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.footerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem 0.5rem;
  /* marsgin-top: 3rem; */
  height: auto;
  margin-top: -120px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.footerBox1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 96rem;
}

.company,
.resources,
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.1;
  margin-bottom: 2rem;
}

.logo1 {
  width: 12rem;
  height: auto;
  margin-top: 2rem;
}

.footerBox1 h3 {
  margin-bottom: 2rem;
}

/* Media Queries */

@media screen and (min-width: 768px) {
  .cards {
    justify-content: flex-start;
  }

  .cardbox {
    margin-top: 0;
  }

  .box,
  .box1 {
    width: 100%;
  }

  .footerBox1 {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .company,
  .resources,
  .about {
    margin-bottom: 0;
  }

  .logo1 {
    margin-top: 0;
  }
}



