nav {
  display: flex;
  color: #ffffff;
  font-size: 18px;
  padding: 20px 0px;
  margin-left: 320px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

nav li {
  display: inline;
  margin: 0 20px;
  align-items: center;
  padding: 10px 80px;
  gap: 124px;
}

nav a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #214966;
  text-decoration: none;
}

.logo {
  position: absolute;
  left: 80px;
  transform: translateY(-35%);
  width: 181px;
  height: 86px;
}

nav ul li a.nav-link:hover {
  border-bottom: 2px solid #214966;
}

@media only screen and (max-width: 768px) {
  nav {
    margin-left: 0;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }

  nav li {
    margin: 10px;
    padding: 0;
    gap: 32px;
  }

  .logo {
    position: static;
    transform: none;
    margin-bottom: 10px;
  }
}

/* For Webkit based browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
